import React, { MouseEvent, useContext, useEffect, useRef, useState } from 'react';

import './Button.scss';
import Cursor from './Cursor';
import { FocusGroupContext } from './FocusGroup';
import { playSound } from './Sounds';

type Props = {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void
    type?: 'button' | 'reset' | 'submit'
    theme?: 'rounded' | 'wing' | 'outline'
    color?: 'black' | 'red' | 'darkred' | 'green' | 'blue'
    index: number
    sound?: string
    className?: string
};

const Button: React.FC<Props> = ({
    children,
    onClick = undefined,
    type = 'button',
    theme = 'rounded',
    color = 'red',
    sound = 'click',
    index,
    className = ''
}) => {
    const ref = useRef<HTMLButtonElement>(null);
    const { focusedIndex, setFocusedIndex } = useContext(FocusGroupContext);

    const onClickNative = (e: MouseEvent<HTMLButtonElement>) => {
        playSound(sound);

        if (onClick) {
            onClick(e);
        }
    };

    const onFocus = () => {
        if (focusedIndex !== index) {
            setFocusedIndex(index);
            playSound('focus');
        }
    };

    const focused = focusedIndex === index;

    return (
        <button ref={ref}
            type={type}
            className={`button button-color-${color} button-theme-${theme} ${focused ? 'is-focused' : ''} ${className}`}
            onClick={onClickNative}
            onFocus={onFocus}
            onMouseOver={onFocus}
        >
            {focused && <Cursor target={ref} />}
            {children}
        </button>
    );
};

export default Button;
