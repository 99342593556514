import React, { useRef } from 'react';
import { useHistory } from 'react-router';

import Statistic from './Statistic';
import Button from './Button';
import FocusGroup from './FocusGroup';

import './BattleReport.scss';

export default function BattleReport() {
    const history = useHistory();
    const ref = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     function onMouseMove(e: MouseEvent) {
    //         function clamp(value: number) {
    //             const LIMIT = 20;
    //             return Math.max(-LIMIT, Math.min(LIMIT, value));
    //         }

    //         const x = clamp((window.innerWidth * 0.25 - e.pageX) / 10);
    //         const y = clamp(-(window.innerHeight * 0.5 - e.pageY) / 50.0);

    //         if (ref.current) {
    //             ref.current.style.transform = `rotateY(${x}deg) rotateX(${y}deg)`;
    //         }
    //     }

    //     document.addEventListener('mousemove', onMouseMove);

    //     return () => document.removeEventListener('mousemove', onMouseMove);
    // }, [ref]);

    return (
        <div className="battle-report">
            <div className="battle-report-left" ref={ref}>
                <div className="battle-report-header">
                    <span className="huge">B</span>ATTLE <span className="huge">R</span>EPORT
                </div>
                <Statistic title="Game Mode">
                    Proud Mode
                </Statistic>

                <Statistic title="Enemies Defeated">
                    2409
                </Statistic>

                <Statistic title="Most Used Keyblade">
                    Wheel of Fate 6:18:55
                </Statistic>

                <Statistic title="Distance Traveled">
                    83.4 km 51.9 mi
                </Statistic>

                <Statistic title="Clear Time">
                    25:25:25
                </Statistic>

                <div className="battle-report-footer">
                    THE END
                </div>
            </div>
            <div className="battle-report-right">
                <FocusGroup indexCount={1}>
                    <Button theme="outline" index={0} onClick={() => history.push('/save')}>Save Menu</Button>
                </FocusGroup>
            </div>
        </div>
    );
}
