import React from 'react';
import Badge from './Badge';

import './BattleReport.scss';
import Button from './Button';
import Fairy from './Fairy';

import './Menu.scss';

type Props = {
    color?: 'red' | 'green' | 'blue'
    munny?: number
    showTime?: boolean

    title: string
    titleButtonLabel: string
    onTitleButtonClick: () => void

    /** Player's location in the menu */
    location?: string

    /** Message at the footer */
    message?: string

    /** Additional class names */
    className?: string
};

const Menu: React.FC<Props> = ({
    color = 'blue',
    munny,
    showTime = false,
    title,
    titleButtonLabel,
    onTitleButtonClick,
    location,
    message,
    className = '',
    children,
}) => {
    return (
        <div className={`menu menu-color-${color} ${className}`}>
            <div className="menu-header">
                <div className="menu-header-left">

                </div>
                <div className="menu-header-right">
                    <div className="menu-header-controls">
                        <Badge className="menu-header-title" color={color} label={title} />
                        <Button
                            index={0}
                            className="menu-header-button"
                            theme="wing"
                            color="red"
                            sound="back"
                            onClick={onTitleButtonClick}
                        >
                            {titleButtonLabel}
                            <Fairy />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="menu-content">
                {children}
            </div>
            <div className="menu-footer">
                <div className="menu-footer-left">
                    {/* Munny / time */}
                </div>
                <div className="menu-footer-right">
                    <p>{message}</p>
                    <div className="menu-mickey" />
                </div>
            </div>
        </div>
    );
}

export default Menu;
