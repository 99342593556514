import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';

import './Video.scss';

type Props = {
    src: string
    potato: string

    onHLSReady: (hls: any) => void;
}

const Video: React.FC<Props> = ({ src, potato, onHLSReady }) => {
    const ref = useRef<HTMLVideoElement>(null);
    const [player, setPlayer] = useState<VideoJsPlayer>();
    const [watchPotato, setWatchPotato] = useState(false);

    useLayoutEffect(() => {
        const opts = {

        };

        const player = videojs(ref.current, opts, () => {
            console.log('onPlayerReady', player);
        });

        // @ts-ignore
        window.player = player;

        player.on('play', () => {
            console.log('PLAY');

            const hls = (player.tech(true) as any).hls;
            onHLSReady(hls);
        });

        player.src({
            src,
            type: 'application/x-mpegURL'
        });

        player.play();

        var potatoBtn = player.controlBar.addChild('button', {
            text: 'Potato'
        });
        potatoBtn.addClass('btn-potato');
        potatoBtn.on('click', () => setWatchPotato((prev) => !prev));

        potatoBtn.contentEl().innerHTML = `
            <span class="potato-popover">
                <img src="${process.env.PUBLIC_URL}/img/potato.svg" />
                <span class="potato-blurb">
                    On wireless and constantly buffering?
                    Or in the middle of nowhere Minnesota?
                    <br/>
                    Try Sybolt Potato!
                </span>
            </span>
        `;

        potatoBtn.setAttribute('title', 'Toggle Potato');

        setPlayer(player);

        return () => {
            player?.dispose();
        }
    }, [ref, src, onHLSReady, setWatchPotato]);

    useEffect(() => {
        if (!player) {
            return;
        }

        player.src({
            src: (watchPotato) ? potato : src,
            type: 'application/x-mpegURL'
        });

        player.play();

    }, [ref, src, potato, player, watchPotato]);

    // Note the outer <div> is required because video.js changes the DOM around.
    // If we don't have something outside of it for React to unmount, we'll get
    // an exception when this component is removed from the virtual DOM.
    return (
        <div className="video">
            <div data-vjs-player>
                <video ref={ref} className="video-js vjs-fill vjs-big-play-centered" controls />
            </div>
        </div>
    );
}

export default Video;
