import React  from 'react';
import { useHistory } from 'react-router';

import FocusGroup from './FocusGroup';
import SaveSlotButton from './SaveSlotButton';
import Menu from './Menu';
import SaveSlots from './SaveSlots';

export default function SaveGame() {
    return (
        <FocusGroup indexCount={100} initialIndex={1}>
            <Menu
                color="green"
                title="SAVE"
                titleButtonLabel="Save"
                onTitleButtonClick={() => alert('TODO: Dialog')}
                message=""
            >
                <SaveSlots theme="green">
                    <SaveSlotButton theme="green"
                        index={1}
                        level={32}
                        munny={363}
                        time="5:06"
                        location="World Map"
                    />
                </SaveSlots>
            </Menu>
        </FocusGroup>
    );
}
