import React from 'react';
import Waves from '../Waves';

export default function Error() {
    return (
        <div className="live-error">
            Something went horribly wrong. Complain to Chase.
        </div>
    );
}
