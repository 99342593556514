import React from 'react';
import Waves from '../Waves';

import './Offline.scss';

export default function Offline() {
    return (
        <div className="live-offline">
            <div className="live-offline-header">
                <h1>Stream is over</h1>
            </div>
            <Waves midColor="#ca8097" lowColor="#dabcce" />
            <div className="live-offline-footer">
                <p>
                    Go home.
                </p>
            </div>
        </div>
    );
}
