import React from 'react';

export default function Corpse() {
    const now = new Date();

    let file = 'sora';
    if (now.getMonth() === 9) {
        file = 'halloween-sora';
    } else if (now.getMonth() === 11) {
        file = 'santa-sora';
    } else {
        // Convoluted moon phase calculation
        // Via https://gist.github.com/endel/dfe6bb2fbe679781948c
        let c = 0;
        let e = 0;
        let jd = 0;
        let b = 0;

        let month = now.getMonth() + 1;
        const day = now.getDate();
        let year = now.getFullYear();

        if (month < 3) {
            year--;
            month += 12;
        }

        ++month;
        c = 365.25 * year;
        e = 30.6 * month;
        jd = c + e + day - 694039.09; // jd is total days elapsed
        jd /= 29.5305882; // divide by the moon cycle
        b = Math.trunc(jd); // int(jd) -> b, take integer part of jd
        jd -= b; // subtract integer part to leave fractional part of original jd
        b = Math.round(jd * 8); // scale fraction from 0-8 and round

        if (b === 4) { // full moon
            file = 'roxas';
        }
    }

    return (
        <div className="corpse">
            <video width="400" height="212" autoPlay loop muted playsInline>
                <source src={`${process.env.PUBLIC_URL}/loops/${file}.mp4`} type="video/mp4" />
            </video>
        </div>
    );
}
