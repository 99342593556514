import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import Live from './Live';

import './App.scss';
import Splash from './Splash';
import GameOver from './KH/GameOver';
import BattleReport from './KH/BattleReport';
import LoadGame from './KH/LoadGame';
import SaveGame from './KH/SaveGame';

const APOLLO_CLIENT = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_HOST,
    cache: new InMemoryCache()
});

export default function App() {
    return (
        <div className="app">
            <ApolloProvider client={APOLLO_CLIENT}>
                <Switch>
                    <Route path="/load" component={LoadGame} />
                    <Route path="/save" component={SaveGame} />
                    <Route path="/report" component={BattleReport} />
                    <Route path="/gameover" component={GameOver} />
                    <Route path="/:id" component={Live} />
                    <Route component={Splash} />
                </Switch>
            </ApolloProvider>
        </div>
    );
}
