import React from 'react';

import './Badge.scss';

type Props = {
    color?: 'red' | 'green' | 'blue'

    /** Additional class names */
    className?: string

    label: string
};

const Badge: React.FC<Props> = ({
    color = 'blue',
    className = '',
    label
}) => {
    return (
        <div className={`badge badge-color-${color} ${className}`}>
            <span className="title-font">{label}</span>
        </div>
    );
}

export default Badge;
