import React, { MouseEvent, useContext, useRef } from 'react';

import Cursor from './Cursor';
import { FocusGroupContext } from './FocusGroup';
import { playSound } from './Sounds';

import './SaveSlotButton.scss';

type Props = {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void
    type?: 'button' | 'reset' | 'submit'
    theme?: 'red' | 'green'
    index: number

    level?: number
    munny?: number
    time?: string
    location?: string
};

const SaveSlotButton: React.FC<Props> = ({
    onClick = undefined,
    type = 'button',
    theme = 'red',
    index,
    level = 0,
    munny = 0,
    time = '',
    location = '',
}) => {
    const ref = useRef<HTMLButtonElement>(null);
    const { focusedIndex, setFocusedIndex } = useContext(FocusGroupContext);

    const onClickNative = (e: MouseEvent<HTMLButtonElement>) => {
        if (level > 0) {
            playSound('click');

            if (onClick) {
                onClick(e);
            }
        } else {
            playSound('not-allowed');
        }
    };

    const onFocus = () => {
        if (focusedIndex !== index) {
            setFocusedIndex(index);
            playSound('focus');
        }
    };

    const focused = focusedIndex === index;
    let offset = { x: 0, y: 0};
    if (focused && ref.current) {
        const rect = ref.current.getBoundingClientRect();
        offset.x = rect.left + window.scrollX;
        offset.y = rect.top + window.scrollY + rect.height * 0.5;
    }

    return (
        <button ref={ref}
            type={type}
            className={`save-slot-button ${focused ? 'is-focused' : ''}`}
            onClick={onClickNative}
            onFocus={onFocus}
            onMouseOver={onFocus}
        >
            {focused && <Cursor target={ref} />}

            <div className={(focused || theme === 'red') ? 'red-panel' : 'green-panel'} />

            <div className="save-slot-button-id">
                {index < 10 ? '0' + index : index}
            </div>

            <div className="save-slot-button-icon">

            </div>

            {level < 1 &&
                <div className="save-slot-button-details">
                    <p>There is no data.</p>
                </div>
            }

            {level > 0 &&
                <div className="save-slot-button-details">
                    <div className="save-slot-button-level">
                        LV {level}
                    </div>
                    <div className="save-slot-button-munny">
                        {munny} Munny
                    </div>
                    <div className="save-slot-button-level">
                        {time}
                    </div>
                    <div className="save-slot-button-location">
                        {location}
                    </div>
                    {/* Donald's dumb face here */}
                </div>
            }
        </button>
    );
};

export default SaveSlotButton;
