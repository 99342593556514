import React from 'react';

type Props = {
    title: string
};

const Statistic: React.FC<Props> = ({
    title,
    children
}) => {
    return (
        <div className="statistic">
            <h2>{title}</h2>
            <p>{children}</p>
        </div>
    );
};

export default Statistic;
