import React  from 'react';
import { useHistory } from 'react-router';

import FocusGroup from './FocusGroup';
import SaveSlotButton from './SaveSlotButton';
import Menu from './Menu';
import SaveSlots from './SaveSlots';

export default function LoadGame() {
    const history = useHistory();

    return (
        <FocusGroup indexCount={100} initialIndex={1}>
            <Menu
                className="load-game"
                color="red"
                title="LOAD"
                titleButtonLabel="Load"
                onTitleButtonClick={() => history.goBack()}
                message="Select file to load."
            >
                <SaveSlots theme="red">
                    <SaveSlotButton theme="red"
                        index={1}
                        level={32}
                        munny={363}
                        time="5:06"
                        location="World Map"
                    />
                </SaveSlots>
            </Menu>
        </FocusGroup>
    );
}
