import React, { useEffect, useRef } from 'react';

import './Waves.scss';

type Props = {
    highColor?: string
    midColor?: string 
    lowColor?: string
}

const Waves: React.FC<Props> = ({
    highColor = '#2e2c2a',
    midColor = '#89c387',
    lowColor = '#c7d7bf',
}) => {
    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = ref.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        const cw = canvas.width;

        function curve(dt: number, style: string, offset: number) {
            ctx.fillStyle = style;
            
            const left = Math.abs(Math.pow(Math.sin(dt / 1000), 2)) * 50 + 80 + offset;
            const right = Math.abs(Math.pow(Math.sin((dt / 1000) + 10), 2)) * 100 + offset;
            const leftCp = Math.abs(Math.pow(Math.sin((dt / 1000) + 2), 2)) * 100 + offset;
            const rightCp = Math.abs(Math.pow(Math.sin((dt / 1000) + 1), 2)) * 100 + offset;
            
            ctx.beginPath();
            ctx.moveTo(0, left);
            
            ctx.bezierCurveTo(
                cw / 3,                 // cp1x
                leftCp,                 // cp1y
                cw / 3 * 2,             // cp2x
                rightCp,                // cp2y 
                cw,                     // end x
                right                   // end y 
            );

            ctx.lineTo(cw, 0);
            ctx.lineTo(0, 0);
            ctx.lineTo(0, left);
            
            ctx.closePath();
            ctx.fill();
        }

        function draw(dt: number) {
            if (canvas) {
                // const y = Math.sin(dt*0.001) * 120;

                // Self assignment is necessary to fix a draw bug
                canvas.width = canvas.width; 

                curve(dt*1.2, lowColor, 20);
                curve(dt*1.2+200, midColor, 10);
                curve(dt, highColor, 0);
            }
            
            window.requestAnimationFrame(draw);
        }

        window.requestAnimationFrame(draw);
    }, [ref, highColor, midColor, lowColor]);

    return (
        <canvas ref={ref} className="waves"></canvas>
    );
}

export default Waves;
