import React from 'react';

import './Splash.scss';
import Waves from './Waves';

export default function Splash() {
    return (
        <main className="splash">
            <div className="splash__title">
                <svg className="logo"
                    xmlns="http://www.w3.org/2000/svg"
                    width="505"
                    height="520"
                    version="1.1"
                    viewBox="0 0 505 520">

                    <g className="logo__world" transform="translate(-122,-255)">
                        <path
                            fill="#e6e1db"
                            fillOpacity={1}
                            stroke="none"
                            className="logo__world--ring"
                            d="m 362.63594,292.72156 c -132.35109,0 -239.65625,107.27391 -239.65625,239.625 0,132.35109 107.30516,239.65625 239.65625,239.65625 132.35109,0 239.625,-107.30516 239.625,-239.65625 0,-132.35109 -107.27391,-239.625 -239.625,-239.625 z m 1.78125,11.0625 c 125.64478,0 227.5,102.17948 227.5,228.21875 0,126.03927 -101.85522,228.21875 -227.5,228.21875 -125.64478,0 -227.5,-102.17948 -227.5,-228.21875 0,-126.03927 101.85522,-228.21875 227.5,-228.21875 z"></path>
                        <path
                            fill="#977fA6"
                            fillOpacity={0.4}
                            stroke="none"
                            className="logo__world--sky"
                            d="m 371.91719,305.90906 c -125.05305,0 -226.4375,101.38445 -226.4375,226.4375 0,125.05305 101.38445,226.4375 226.4375,226.4375 125.05305,0 226.4375,-101.38445 226.4375,-226.4375 0,-125.05305 -101.38445,-226.4375 -226.4375,-226.4375 z m 14.28125,48.21875 c 79.09506,0 143.21875,64.12369 143.21875,143.21875 0,79.09506 -64.12369,143.21875 -143.21875,143.21875 -79.09506,0 -143.21875,-64.12369 -143.21875,-143.21875 0,-79.09506 64.12369,-143.21875 143.21875,-143.21875 z"></path>
                        <path
                            fill="#e6e1db"
                            fillOpacity={1}
                            stroke="none"
                            className="logo__world--moon"
                            d="m 552.6957,327.75281 -58.6391,58.98627 c 29.49716,28.46876 47.84547,68.43823 47.84547,112.67041 0,86.45426 -70.08501,156.53928 -156.53928,156.53928 -43.91059,0 -83.58055,-18.0929 -112.00765,-47.21426 l -57.25045,57.56605 c 43.4394,45.18289 104.4941,73.31467 172.13009,73.31467 131.87262,0 238.78552,-106.88134 238.78552,-238.75396 0,-68.13323 -28.54919,-129.60318 -74.3246,-173.10846 z"></path>
                    </g>
                </svg>
                <div>sybolt</div>
            </div>
            <Waves />
        </main>
    );
}
