import React from 'react';
import { useHistory } from 'react-router';

import Button from './Button';
import Corpse from './Corpse';
import FocusGroup from './FocusGroup';

import './GameOver.scss';

export default function GameOver() {
    const history = useHistory();

    return (
        <div className="game-over">
            <h1>The stream has ended</h1>
            <Corpse />

            <FocusGroup indexCount={2}>
                <Button index={0} theme="outline">Continue</Button>
                <Button index={1} theme="outline" onClick={() => history.push('/load')}>Load Game</Button>
            </FocusGroup>
        </div>
    );
}
