import React, { useState } from 'react';

type Props = {
    indexCount: number
    initialIndex?: number
};

interface IFocusGroupContext {
    setFocusedIndex: (index: number) => void
    focusedIndex: number
}

export const FocusGroupContext = React.createContext<IFocusGroupContext>(
    {} as IFocusGroupContext
);

const FocusGroup: React.FC<Props> = ({
    children,
    initialIndex = 0,
    indexCount
}) => {
    const [focusedIndex, setFocusedIndex] = useState<number>(initialIndex);

    // mouse move / mouse down / keyboard button events to cycle focus
    // identify the current focused element in the group
    // and set the pointer to that element.
    // (or that's just done in the buttons? Idk)

    const value: IFocusGroupContext = {
        focusedIndex,
        setFocusedIndex
    };

    return (
        <FocusGroupContext.Provider value={value}>
            <div className="focus-group">
                {children}
            </div>
        </FocusGroupContext.Provider>
    );
};

export default FocusGroup;
