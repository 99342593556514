import React, { useEffect, useState } from 'react';

import './Cursor.scss';

type Props = {
    target: React.RefObject<HTMLElement>
}

const Cursor: React.FC<Props> = ({ target }) => {
    const [style, setStyle] = useState({ top: -9999, left: 0 });

    useEffect(() => {
        let offset = { top: 0, left: 0};
        if (target.current) {
            const rect = target.current.getBoundingClientRect();
            offset.left = rect.left + window.scrollX;
            offset.top = rect.top + window.scrollY + rect.height * 0.5;
            setStyle(offset);
        }
    }, [target]);

    return (
        <div className="cursor" style={style} />
    );
};

export default Cursor;