import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import Video from './Video';
import Waves from '../Waves';
import Offline from './Offline';
import Error from './Error';

import './index.scss';

const WATCH_STREAM = gql`
    mutation ($id: ID!) {
        watch(id: $id) {
            id
            ok
            watcher {
                id
                name
                avatar
                playlistUrl
            }
        }
    }
`;

const SYNC_STREAM = gql`
    mutation ($id: ID!, $timestamp: Float!) {
        sync(id: $id, timestamp: $timestamp) {
            ok
            watcher {
                id
                name
                avatar
                playlistUrl

                streamer {
                    # Grab an updated list of active watchers
                    watchers {
                        name
                        avatar
                        timestamp
                        updated
                    }
                }
            }
        }
    }
`;

const DROP_SESSION = gql`
    mutation ($id: ID!) {
        drop(id: $id) {
            ok
        }
    }
`


// Initial GQL mutation. On connect, load the <Video> component with the right src

// Periodic GQL mutation to pull timestamp and push it to the server.
// If the response comes back null, go into a disconnect state.

// 'https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8'

// export default function Watch() {
//     const [hls, setHLS] = useState<any>(); // video.js HLS tech
//     const url = 'https://sybolt.com/hls/live_src/index.m3u8'

//     return (
//         <div className="live">
//             <Video src={url} onHLSReady={setHLS} />
//         </div>
//     )
// }

export default function Watch() {
    const { id } = useParams<{ id: string }>();
    const [session, setSession] = useState<string>();
    const [watcher, setWatcher] = useState<any>(); // Watcher GQL type
    const [error, setError] = useState<any>();
    const [hls, setHLS] = useState<any>(); // video.js HLS tech

    const [watch, watchResult] = useMutation(WATCH_STREAM, {
        onCompleted: (data) => {
            setError(undefined);

            // Refresh our session ID and watcher instance
            if (data.watch.ok) {
                setSession(data.watch.id);
                setWatcher(data.watch.watcher);
            }
        },
        onError: (error) => {
            // Set our error state
            setError(error);
        }
    });

    const [sync, syncResult] = useMutation(SYNC_STREAM, {
        onCompleted: (data) => {
            setError(undefined);

            if (data.sync.ok) {
                setWatcher(data.sync.watcher);
            } else {
                setSession(undefined);
                // Don't clear watcher - we'll use that to identify
                // that we *had* a session and lost it when the streamer
                // went offline.
            }
        },
        onError: (error) => {
            setError(error);
        }
    });

    const [drop, dropResult] = useMutation(DROP_SESSION);

    // In the background, either fire off a watch() to start a session
    // if we don't have one, or a sync() to send an update for our current session
    useEffect(() => {
        function watchOrSync() {
            if (session) {
                let timestamp = 0;
                try {
                    timestamp = hls.playlists.media().syncInfo.mediaSequence;
                } catch (e) {
                    // noop
                }

                sync({
                    variables: {
                        id: session,
                        timestamp
                    }
                });
            } else {
                watch({ variables: { id }});
            }
        }

        const interval = window.setInterval(watchOrSync, 5000);
        watchOrSync();

        return () => window.clearInterval(interval);
    }, [id, session, hls, watch, sync]);

    // Add a beforeunload handler so that we can (try to)
    // remove our watcher session before the tab closes.
    useEffect(() => {
        const onBeforeUnload = () => {
            if (session) {
                drop({ variables: { id: session }});
            }
        };

        window.addEventListener('beforeunload', onBeforeUnload);
        return () => window.removeEventListener('beforeunload', onBeforeUnload);
    }, [session, drop]);

    return (
        <div className="live">
            {error &&
                <Error />
            }

            {process.env.NODE_ENV === 'development' &&
                <div className="live-debug">
                    <pre>
                        {error &&
                            <span>ERROR: {JSON.stringify(error, undefined, 2)}</span>
                        }
                        MEDIA:
                        {JSON.stringify(
                            hls?.playlists.media(),
                            undefined, 2
                        )}
                        SESSION: {session}<br/>
                        WATCHER: {JSON.stringify(watcher, undefined, 2)}
                    </pre>
                </div>
            }

            {!session && !watcher &&
                <div className="live-offline">
                    <div className="live-offline-header">
                        <h1>Nothing to see here</h1>
                    </div>
                    <Waves midColor="#8fadba" lowColor="#c2d3d3" />
                    <div className="live-offline-footer">
                        <p>
                            If you are waiting for someone to start streaming at this link,
                            their stream will appear here once they do. If you got here by
                            accident, you will probably be waiting quite a while.
                            Maybe go watch <a href="https://openings.moe" target="_blank" rel="noopener noreferrer">
                            a random anime opening</a> instead.
                        </p>
                    </div>
                </div>
            }

            {!session && watcher &&
                <Offline />
            }

            {session &&
                // TODO: Stream name should be a field from the GraphQL API but
                // it's a full URL (prior implementation before I had multiple streams setup).
                // Since the URI fragment matches 1-to-1 with stream names, we just use that.
                <Video
                    src={`https://sybolt.com/hls/${id}_src/index.m3u8`}
                    potato={`https://sybolt.com/hls/${id}_potato/index.m3u8`}
                    onHLSReady={setHLS}
                />
            }
        </div>
    );
}
