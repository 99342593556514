import React, { MouseEvent } from 'react';
import SaveSlotButton from './SaveSlotButton';

import './SaveSlots.scss';

type Props = {
    onSelect?: (event: MouseEvent<HTMLButtonElement>) => void
    theme?: 'red' | 'green'
}

const SaveSlots: React.FC<Props> = ({
    onSelect,
    theme,
    children
}) => {
    return (
        <div className="save-slots">
            <div className={`${theme}-panel`} />
            <div className="save-slots-scroller">
                {children}

                {/* Fill the rest with unused slots */}
                {[...Array(99 - React.Children.count(children))].map((e, i) =>
                    <SaveSlotButton theme={theme} key={i + 2} index={i + 2} />
                )}
            </div>
        </div>
    );
};

export default SaveSlots;
