import React from 'react';

import './Fairy.scss';

export default function Fairy() {
    return (
        <div className="fairy">
            <div className="fairy-dot" />
        </div>
    );
}
